import React,{useState,useEffect,useRef,useMemo} from 'react'
import { Container,Grid, TextField, Typography, TextareaAutosize, Button, Paper,Divider,Box} from '@mui/material';
import { styled } from '@mui/system';
import { findDOMNode } from 'react-dom'
import { useNavigate } from 'react-router-dom';
import { blobToDataURL } from 'blob-util'




import { useLiveQuery } from 'dexie-react-hooks';
import db from '../browserDb/db'

import soundBytes from 'src/assets/images/soundBytes.mp3'
import soundBytes2 from 'src/assets/images/soundBytes2.mp3'
import { FaArrowCircleLeft, FaEnvelope, FaInbox } from 'react-icons/fa';
import { getParentChat } from 'src/redux/actions/chat.action';
import { useDispatch, useSelector } from 'react-redux';

function InboxListPage() {
  const navigate = useNavigate()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    height:"90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
 





/*DEXIE MANIPULATION LOGIC */
const URLSound = window.URL || window.webkitURL;
const [savedMedia,setSavedMedia] = useState([])
const [videoLink,setVideoLink] = useState(null)
let Files = useLiveQuery(() => db.savedCourses.where("courseName").notEqual("Sample name").toArray(),[]);
const linkMaker = (blob) => {
 let link;

  blobToDataURL(blob).then((url)=>{
   link =url
   //console.log("final url is",url)
    
    setVideoLink(url)
    
  })

  

}

useEffect(()=>{


setSavedMedia(Files)

//linkMaker(savedMedia[0].fileObject)
},[Files])








/*DEXIE MANIPULATION LOGIC END */




/*MODAL MANIPULATION LOGIC */

  const [open, setOpen] = React.useState(false);
 
/*MODAL MANIPULATION LOGIC */


 /*video manipulation logic */
 
  const [videoTime,setVideoTime] = useState(false)
  const [fullScreen, setFullScreen] = useState(false);


  
  const videoRef = useRef(true)
 

  const handleEsc = (event) => {
   
    window.removeEventListener('fullscreenchange', handleEsc)
    setTimeout(()=>{setOpen(false); setFullScreen(!fullScreen); setVideoTime(false)},10)
    
  };


  const doVideoActions = () => {
    setOpen(true)
    
    setTimeout(
     ()=> {
    
    setVideoTime(!videoTime)
    
     if(!videoTime){
      findDOMNode(videoRef.current).requestFullscreen()
      }
    },10) 

    setTimeout(()=>(window.addEventListener('fullscreenchange', handleEsc)),1000)
  }

  
  
  
  useEffect(()=>{
 
    if(open === false){
      setTimeout(()=>(window.removeEventListener('fullscreenchange', handleEsc)),10)
    }

  },[open])

  /*video manipulation logic end */

 


  /*CHAT FETCHING manipulation logic end */
  const { user,school } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  useEffect(()=>{
    /*FETCHING FROM ONE  MESSAGES ARRAY that accounts FOR ONLY A SINGLE CHAT,
     DOESNT ACCOUNT FOR DIFFERENT CHATS FOR DIFFERENT CHILDREN  */

  dispatch(getParentChat(user && user))
 console.log("USER BEING SET IS--->",user)

   },[user])



  return (
    <>
    <Container maxWidth="xs" sx={{backgroundColor:"white", border:"1px solid lightgray",fontSize:"0.85rem",height:"85vh"}}> 

    
    {  <div  onClick ={()=>{navigate('/dashboard/home')}} style={{fontSize:"2rem",color:"white",fontWeight:"900",color:/*themeColor?themeColor:*/"#000000",position:"relative",top:"1rem",left:"-1rem",marginBottom:"2rem",cursor:"pointer"}}>
            <FaArrowCircleLeft/>
           </div>}


    <center  style={{ display: 'flex', justifyContent: 'center',marginTop:"30px",marginBottom:"50px",gap:"10px" }}>
    
    <Button   variant="contained" 
     style={{  backgroundColor:"#000000",color:"#FFFFFF",width:"40%",
     padding: '8px'}}
    
     onClick={()=>{navigate('/dashboard/inbox-list')}}
     >
     Boîte de Réception
     </Button>

     <Button   variant="contained" 
     style={{backgroundColor: "#FFFFFF",color:"#000000",
     border:"1px solid black", fontSize:"12px",width:"40%",
     padding: '8px'}}
     onClick={()=>{navigate('/dashboard/announcements')}}
     >
     
Annonces
     </Button>


</center>


   {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection:"column",marginBottom:"1rem"}}>
    <center>
    <p style={{position:"relative",display:"block",fontWeight:"bold",fontSize:"0.9rem",marginBottom:"1rem",borderBottom:"3px solid red",width:"max-content"}}>Politique de confidentialité </p>
    <p style={{position:"relative",display:"block",fontWeight:"bold",fontSize:"0.9rem",borderBottom:"3px solid red",width:"max-content"}}>de Bonecole INC SARLU</p>
    </center>
    </Grid>*/}


      
     <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '20px',display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"0.5rem" }}>
            Messages <FaEnvelope/>
          </Typography>
          <br />

      {
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'max-content',
              width: 390,
             // border: '1px solid black',
             marginBottom:"0.5rem",
              backgroundColor:"#F5F5F5",
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              fontSize:"1rem"
              ,cursor:"pointer"
            }}

            onClick={()=>{navigate('/dashboard/chat')}}
          >
            {/*<Grid container justifyContent="space-around" alignItems="center">*/}
             
              <Grid item style={{fontWeight:"bold"}} >
               {school && school.name}
              </Grid>

              <Grid item >
              les résultats de votre enfant...
              </Grid>

            {/*</Grid>*/}
          </Paper> 
        }




      {/*   <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'max-content',
              width: 390,
              //border: '1px solid black',
              backgroundColor:"#F5F5F5",
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              fontSize:"1rem"
              ,cursor:"pointer"
            }}

            onClick={()=>{navigate('/dashboard/chat')}}
          >
          
              <Grid item style={{fontWeight:"bold"}}>
                Lisa Ann
              </Grid>
              <Grid >
               Francis Alexandre...
              </Grid>

            

          
          </Paper>
          */}





   <p style={{paddingTop:"1.5rem",paddingBottom:"1.5rem"}}>
  

   
   </p>
       

</Container>
    </>
  );
}

export default InboxListPage;