import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Checkbox, Typography, IconButton, Button,Select,MenuItem } from '@mui/material';
import { buyCourseUpdateUser, fetchPurchasedCourse, savePayTokenToDatabase } from '../redux/actions/cart.action';
import { useSelector, useDispatch } from 'react-redux';
import { buyCourse,saveOrderIdToDatabase } from 'src/redux/actions/cart.action';
import MTNLOGO from '../assets/images/MTN-logo.png';
import PAYCARDLOGO from '../assets/images/paycard-logo.png';
import ORANGELOGO from '../assets/images/orange-logo.png';
import LockIcon from '@mui/icons-material/Lock';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import axios from 'axios';
import * as uuid from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import avatar from 'src/assets/images/blankavatar.png'

import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdLogout, MdOutlineAttachEmail } from "react-icons/md";
import { FcSpeaker } from "react-icons/fc";

import { BiParty } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";

import { FaCaretUp,FaCaretDown } from "react-icons/fa";
import { fetchStudentResults } from 'src/redux/actions/group.action';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';
import { logout } from 'src/redux/actions/auth.action';





const AttendancePage = () => {
  const [pcChecked, setPcChecked] = useState(false);
  const [mtnChecked1, setMtnChecked1] = useState(false);
  const [mtnChecked2, setMtnChecked2] = useState(false);

  const [classes,setClasses] = useState('')

  const [orangeChecked, setOrangeChecked] = useState(false);
  const [momoToken, setMomoToken] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { purchasedCourses } = useSelector((state) => state.cart);
  const { cart } = useSelector((state) => state.cart);
  //console.log("THIS IS THE STRUCTURE OF CART ITEMS--->",cart)
  const [isLoading, setIsLoading] = useState(false);
 // const totalPrice = cart.reduce((acc, item) => {
 //   const itemPrice = parseFloat(item.price && item.price.replace(',', ''));
 //   return acc + itemPrice;
 // }, 0);

  const courseIdArray = cart.map((item)=>(item.id))
  
  const cartToSubmit = {courses:cart,affiliateId:user &&user.affiliate} //cart.map((item)=>({...item,affiliateId:user &&user.affiliate}))
  const handleOrangeCheckBox = () => {
    setOrangeChecked(true);
    setMtnChecked1(false);
    setMtnChecked2(false);
    setPcChecked(false);
  };



  const { themeColor } = useSelector((state) => state.settings);
  const {school } = useSelector((state) => state.auth);
  const { presentStudent } = useSelector((state) => state.group);

  console.log("present student iss->",presentStudent)

  const [selectedOption, setSelectedOption] = useState('');
 

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };



  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])


  
 const { studentResults } = useSelector((state) => (state.group));


  useEffect(() => {

  if(!location.state.student.studentId){
    navigate('/dashboard/home')
  }
  console.log("LOCATION STATE IS (attendance page)--->",location.state)

  dispatch(fetchStudentResults(location.state && location.state.student.studentId))

  }, []);

  console.log("THE RESULTS (attendance page) ARE--->",studentResults)



 


  

  return (
    <Container
      maxWidth="xs"
      sx={{ backgroundColor: 'white', border: '1px solid lightgray', fontSize: '0.85rem', minHeight: '500px' }}
    >
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between',alignItems:"center", flexDirection: 'row', marginBottom: '1rem', position: 'relative',
        top:"0.5rem", }}
      >
        <div>
          <p
            style={{
              position: 'relative',
              top:"0.5rem",
              fontWeight: 'bold',
              fontSize: '1.2rem',
              
              width: 'max-content',
             // marginTop: '20px',
            }}
          >
          {presentStudent.fname + " " + presentStudent.lname}{"'s"}{" "}Attendance 
          </p>
        </div>

        <div style={{ position: 'relative',
              top:"0.5rem",display:"flex",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
        <img  src={avatar} style={{ borderRadius:"50%" ,heigth:"45px",width:"45px",}}/> 

      
       <MdLogout style={{fontSize:"1.6rem",cursor:"pointer"}} onClick={()=>{dispatch(logout(navigate))}} />
       </div>

      </Grid>
      <Grid container xs={12} style={{ paddingTop: '1.5rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column',minWidth:"100%" }}>


        <Grid item xs={12} spacing={2} style={{marginTop:"1rem",marginBottom:"2rem",gap:"10px", display: 'flex',flexDirection:"column", justifyContent: 'space-between',alignItems:"space-between" }}>
        {presentStudent && presentStudent.class &&  Number(presentStudent.class.slice(presentStudent.class.length-2,presentStudent.class.length)) >= 7 ?
        
        <Select
          value={selectedOption}
          onChange={handleSelectChange}
          displayEmpty
          label=""
          sx={{
          //   minHeight: 30,
            minWidth: 120,
            p: 1,
          }}
        >
          <MenuItem value="">
           Filtrer Par
          </MenuItem>

       
         
          <MenuItem value={"Terme 1"}>Terme 1</MenuItem>
          <MenuItem value={"Terme 2"}>Terme 2</MenuItem>
         
       
        </Select>



       :
       
       <Select
       value={selectedOption}
       onChange={handleSelectChange}
       displayEmpty
       label=""
       sx={{
       //   minHeight: 30,
         minWidth: 120,
         p: 1,
       }}
     >
       <MenuItem value="">
        Filtrer Par
       </MenuItem>

    
      
     
       <MenuItem value={"Terme 1"}>Terme 1</MenuItem>
       <MenuItem value={"Terme 2"}>Terme 2</MenuItem>
       <MenuItem value={"Terme 3"}>Terme 3</MenuItem>
         
    
     </Select>
       
                 
       }
        </Grid>


           
           <Grid xs={12} style={{width:"97.5%",fontSize:"1.1rem",fontWeight:"bold"}} container justifyContent="space-around" alignItems="center">
              <Grid item style={{width:"30%",textAlign:"left",position:"relative",left:"20px"}}>
               Week 
              </Grid>
              

              <Grid item style={{width:"14%"}} >
                M
              </Grid>

              <Grid item style={{width:"14%"}}>
                T
              </Grid>

              <Grid item style={{width:"14%"}}>
                W
              </Grid>

              <Grid item style={{width:"14%"}}>
                T
              </Grid>

              <Grid item style={{width:"14%"}} >
                F
              </Grid>

            </Grid>
          
          <br />

       
    
            
   {/*1 */}

   {presentStudent && presentStudent.attendance ?  presentStudent.attendance.map((item,index)=>{
        
    

        return (
    <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
              //border: '1px solid black',
              backgroundColor:"white",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem"
            }}
          >
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item style={{width:"30%",textAlign:"left"}}>
               Week {` ${item.week}`}
              </Grid>
              

              <Grid style={{width:"14%"}} >
                {item.mon ? "Y":"N"}
              </Grid>

              <Grid style={{width:"14%"}}>
                {item.tue ? "Y":"N"}
              </Grid>

              <Grid style={{width:"14%"}}>
                {item.wed ? "Y":"N"}
              </Grid>

              <Grid style={{width:"14%"}}>
                {item.thu ? "Y":"N"}
              </Grid>

              <Grid style={{width:"14%"}}>
                {item.fri ? "Y":"N"}
              </Grid>

            </Grid>

            

            <Grid  style={{width:"100%",borderBottom:"1px solid lightgray"}}>
               
               </Grid>


          </Paper>
        )

      }): 


      <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 50,
        width: 390,
        //border: '1px solid black',
        backgroundColor:"white",
        justifyContent: 'center',
        alignItems: 'center',
        fontSize:"1rem"
      }}
    >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item style={{width:"100%",textAlign:"center"}}>
        No Attendance Recorded so far. 
        </Grid>
        


      </Grid>

      



    </Paper>
  




        }


    



         {/* IN CASE I LATER NEED A BUTTON TO DO SOMETHING TO THIS ATTENDANCE
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
              marginBottom:"0.5rem",
              marginTop:"1rem",
              backgroundColor:themeColor?themeColor:"#D72A34",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem",
              cursor:"pointer"
            }}
          >
            <Grid container justifyContent="center" alignItems="center">
            
              <Grid item  
              style={{fontSize:"1.3rem",color:"white"}} onClick={()=>{navigate('/dashboard/home')}}>
               Done
              </Grid>

            
            </Grid>
          </Paper> */}



      <br/>
      <br/>





        
        </div>
      </Grid>

      
  
    </Container>
  );
};
export default AttendancePage;