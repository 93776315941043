import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,Paper } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import bonLogo from 'src/assets/images/bonlogo.png'

import Searchbar from './Searchbar2';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Searchbar2 from './Searchbar2';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import { useLocation,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
   // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
   width:"100%"
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  width:"100%",
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPathname = location.pathname;

  const { themeColor,themeImage } = useSelector((state) => state.settings);
  const {school } = useSelector((state) => state.auth);
  const { presentStudent } = useSelector((state) => state.group);

  console.log("school is-->",school)

  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])



  const clickOnly = (e) =>{
    e.stopPropagation()
    navigate('/dashboard/home')
  }

  return (
    <StyledRoot>
      <StyledToolbar>
        <Paper onClick={(e)=>{clickOnly(e)}}
          //onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'flex' },
            justifyContent:"flex-start",
            alignItems:"center",
            backgroundColor:"transparent",
            width:"40rem"
          }}
        >
           <img  style={{maxHeight:"80px"}} src ={themeImage?themeImage:bonLogo}/> 
            &nbsp; &nbsp;
           <span style={{color:"black",fontSize:"1.4rem"}}><b>{school && school.name }</b></span>
          {/*<Iconify icon="eva:menu-2-fill" />*/}
        </Paper>
       
        
        {/* <Searchbar /> */}
        {/* <Searchbar2 /> */}
        <Box sx={{ flexGrow: 1 }} />

       <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
         <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'block' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        
        </Stack>
        
      </StyledToolbar>
    </StyledRoot>
  );
}
