import SvgColor from '../../../components/svg-color';
import {AiOutlineHome, AiOutlinePoweroff} from 'react-icons/ai'
 import {AiOutlineInbox} from 'react-icons/ai'
 import {AiOutlineBulb} from 'react-icons/ai'
 import {CgToolbox} from 'react-icons/cg'
 import {FiSettings} from 'react-icons/fi' 
 import {AiOutlineLock,AiOutlineSetting} from 'react-icons/ai'
 import {BsFillChatFill} from 'react-icons/bs'
 import {BiLogOut} from 'react-icons/bi'
 import {BiBasket} from 'react-icons/bi'
 import {SlDocs} from 'react-icons/sl'
import { FaLock, FaPersonBooth } from 'react-icons/fa';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Accueil',
    path: '/dashboard/home',
    icon:<AiOutlineHome/>
    // icon: icon('ic_analytics'),
  },
  {
    title: 'Messages',
    // path: '/dashboard/test-payment',
    path: '/dashboard/announcements',
    icon:<AiOutlineInbox/>
    // icon: icon('ic_analytics'),
  },
  {
    title: 'Paramètres',
    path: '/dashboard/settings',
    icon:<AiOutlineSetting/>,
  //  children:[
//
  //     {
  //  title: 'Change Password',
  //  path: '/dashboard/settings',
  //  icon:<AiOutlineInbox/>,
  //   
  //  },
//
  // {
  //  title: 'Change Profile Picture',
  //  path: '/dashboard/settings',
  //  icon:<AiOutlineInbox/>,
  //   
  // },
//
//
  //  ]  
   
  },
  {
    title: 'Se Déconnecter',
     path: '/logout',
    //path: '#',
    icon: <AiOutlinePoweroff />
  },
  /*{
    title: 'Packs',
    path: '/dashboard/packs',
    icon:<SlDocs/>,
     icon: icon('ic_analytics'),
},*/
 


//  {
//    title: 'Mon panier',
//    path: '/dashboard/my-cart',
//    icon:<BiBasket/>
//    // icon: icon('ic_analytics'),
//  },
//  {
//    title: 'Profil',
//    path: '/dashboard/profile',
//    icon:<CgToolbox/>
//    // icon: icon('ic_analytics'),
//  },
//  {
//    title: 'CGU',
//    path: '/dashboard/privacy',
//    icon:<SlDocs/>
//    // icon: icon('ic_analytics'),
//  },
//
//  {
//    title: 'Se déconnecter',
//    path: '/dashboard/logout',
//    icon:<BiLogOut/>
//    // icon: icon('ic_analytics'),
//  },
// 

  
];

export default navConfig;
