import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Checkbox, Typography, IconButton, Button } from '@mui/material';
import { buyCourseUpdateUser, fetchPurchasedCourse, savePayTokenToDatabase } from '../redux/actions/cart.action';
import { useSelector, useDispatch } from 'react-redux';

import {savePresentStudent} from '../redux/reducers/group.slice';

import axios from 'axios';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import avatar from 'src/assets/images/corp2.jpeg'

import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";

import { FcSpeaker } from "react-icons/fc";

import { BiParty } from "react-icons/bi";
import { FaCalendarAlt, FaCheck, FaRegCalendarCheck } from "react-icons/fa";

import { FaCaretUp,FaCaretDown } from "react-icons/fa";

import {SlideDown} from 'react-slidedown'
import DEFAULTIMG from '../assets/images/charles.png'
import { Opacity } from '@material-ui/icons';


const StudentDropdownComponent = ({student,name,teacher,level,studentId,index,profileImage}) => {
  
  const { user } = useSelector((state) => state.auth);
  const { presentStudent } = useSelector((state) => state.group);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { purchasedCourses } = useSelector((state) => state.cart);

  const [isLoading, setIsLoading] = useState(false);

console.log("student's profile image is -->",profileImage)



 // useEffect(() => {
 //   dispatch(fetchPurchasedCourse(user?.uid));
 //
 // }, []);

 


 const gradientOverlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
 background: !profileImage?`linear-gradient(0deg, #DCDCDC, #F5F5F5)`:"none",
   boxShadow:!profileImage? "inset 0 0px 15px 20px grey":"none", /* Inset box shadow */
   borderRadius:"0.7rem",
   
   opacity:"0.5"
};


  return (
   
      <Grid container xs={12} style={{ paddingTop: '1.5rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
       {  

         profileImage && profileImage.length >2 ?
        
          <div 
          style={{
            padding: "2rem",
            display: 'flex',
            flexDirection: 'column',
            height: "100px",
            width: "390px",
            border: '0px solid black',
            borderRadius:"0.7rem",
            justifyContent: 'center',
            alignItems: 'center',
            position:"relative",
            cursor:"pointer",
            backgroundImage: `url(${profileImage})`,
            backgroundPosition: "50% 50%",
            /*backgroundSize: "400px 400px",*/
            backgroundSize: "cover",
            
           
            //background: '#F4C109DB',
            
          }}
          >
          <Grid container justifyContent="flex-start" alignItems="center">
          
          
          
          
          
            <Grid item onClick={()=>{
                          if(studentId !== presentStudent.studentId){
                          dispatch(savePresentStudent(student))
                          }else{
                           dispatch(savePresentStudent({studentId:null}))
                          }
                       }}>
          
              {presentStudent && presentStudent.studentId=== studentId?
              <FaCaretUp style={{ position:"absolute",top:"0.8rem" ,left:"21rem",color:"white",fontSize:"1.6rem"}} />:
              <FaCaretDown style={{ position:"absolute",top:"0.8rem" ,left:"21rem",color:"white",fontSize:"1.6rem"}} />}
            {/*<img src={profileImage?profileImage:DEFAULTIMG} alt={"student's profile image"}/>*/}
            </Grid>
            <Grid item style={{ position:"relative",bottom:"-2rem" ,left:"-1.5rem",color:"white",fontSize:"1.3rem"}}>
             {name}
            </Grid>
          </Grid>
          </div>
           

          :
          <div 
            style={{
              padding: "2rem",
              display: 'flex',
              flexDirection: 'column',
              height: "100px",
              width: "390px",
              border: '0px solid black',
              borderRadius:"0.7rem",
              justifyContent: 'center',
              alignItems: 'center',
              position:"relative",
              cursor:"pointer",
             
              backgroundImage:`url(${DEFAULTIMG})`,
              backgroundPosition: "50% 50%",
              /*backgroundSize: "400px 400px",*/
              backgroundSize: "cover",
              
             
              //background: '#F4C109DB',
              
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
           
           
            <div style={{

             position: 'absolute',
             top: 0,
             left: 0,
             width: '100%',
             height: '100%',
             background: `linear-gradient(0deg, #000000,#A9A9A9)`,
              
              borderRadius:"0.7rem",
              
              opacity:"0.9"

            }}>

            </div>
            

              <Grid item onClick={()=>{
                            if(studentId !== presentStudent.studentId){
                            dispatch(savePresentStudent(student))
                            }else{
                             dispatch(savePresentStudent({studentId:null}))
                            }
                         }}>

                {presentStudent && presentStudent.studentId=== studentId?
                <FaCaretUp style={{ position:"absolute",top:"0.8rem" ,left:"21rem",color:"white",fontSize:"1.6rem"}} />:
                <FaCaretDown style={{ position:"absolute",top:"0.8rem" ,left:"21rem",color:"white",fontSize:"1.6rem"}} />}
              {/*<img src={profileImage?profileImage:DEFAULTIMG} alt={"student's profile image"}/>*/}
              </Grid>
              <Grid item style={{ position:"relative",bottom:"-2rem" ,left:"-1.5rem",color:"white",fontSize:"1.3rem"}}>
               {name}
              </Grid>
            </Grid>
          </div>

          }
          <br />



    <SlideDown style={{width:"100%"}}>
       {presentStudent &&  presentStudent.studentId === studentId &&
       
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              height: 100,
              width: 360,
              
              //border: '1px solid black',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >


        {/*1 */}
        <div style={{display: 'flex',
              flexDirection: 'column',fontSize:"1.1rem"}}>
          <p> Niveau:<b> {level} </b> </p>
          <p> Professeur: <b>{teacher}</b>  </p>

        </div>

    {/*2 */}
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"0.3rem"}}>
 
            <div style={{borderRadius:"50%",backgroundColor:"#EFEFEF",padding:"0.4rem"}}>
          <MdOutlineAttachEmail onClick={()=>{navigate('/dashboard/inbox-list',{state:{student:student}})}}   style={{fontSize:"1.4rem",cursor:"pointer"}}/> 
           </div>
     
     
           <div style={{borderRadius:"50%",backgroundColor:"#EFEFEF",padding:"0.4rem"}}>
          <HiOutlineDocumentReport  onClick={()=>{navigate('/dashboard/results',{state:{student:student}})}}  style={{fontSize:"1.4rem",cursor:"pointer"}}/>
          </div>


          <div style={{borderRadius:"50%",backgroundColor:"#EFEFEF",padding:"0.4rem"}}>
          <FaRegCalendarCheck  onClick={()=>{navigate('/dashboard/attendance',{state:{student:student}})}}  style={{fontSize:"1.4rem",cursor:"pointer"}}/>
          </div>
          

         
        </div>


      </Paper>
   }
  </SlideDown>
  


    
        </div>
      </Grid>

    
  );
};
export default StudentDropdownComponent;