import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Checkbox, Typography, IconButton, Button } from '@mui/material';
import { buyCourseUpdateUser, clearPayTokenFromDatabase, fetchCartToProcessFromUser, fetchPurchasedCourse, paySchoolFees, savePayTokenToDatabase } from '../redux/actions/cart.action';
import { useSelector, useDispatch } from 'react-redux';
import { buyCourse,saveOrderIdToDatabase } from 'src/redux/actions/cart.action';
import MTNLOGO from '../assets/images/MTN-logo.png';
import PAYCARDLOGO from '../assets/images/paycard-logo.png';
import ORANGELOGO from '../assets/images/orange-logo.png';
import LockIcon from '@mui/icons-material/Lock';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import axios from 'axios';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import avatar from 'src/assets/images/blankavatar.png'

import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";

import { FcSpeaker } from "react-icons/fc";

import { BiParty } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";

import { FaCaretUp,FaCaretDown } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

import {SlideDown} from 'react-slidedown'
import StudentDropdownComponent from './StudentDropdownComponent';
import { saveMyStudents } from 'src/redux/reducers/group.slice';
import { fetchMyStudents } from 'src/redux/actions/group.action';
import { logout } from 'src/redux/actions/auth.action';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';


const WelcomeParentPage = () => {
  const [pcChecked, setPcChecked] = useState(false);
  const [mtnChecked1, setMtnChecked1] = useState(false);
  const [mtnChecked2, setMtnChecked2] = useState(false);

  const [orangeChecked, setOrangeChecked] = useState(false);
  const [momoToken, setMomoToken] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { purchasedCourses } = useSelector((state) => state.cart);
  const { cart,cartToProcess,mostRecentOrderAmount,mostRecentOrderId,mostRecentPayToken} = useSelector((state) => state.cart);
  //console.log("THIS IS THE STRUCTURE OF CART ITEMS--->",cart)
  const [isLoading, setIsLoading] = useState(false);
  //const totalPrice = cart.reduce((acc, item) => {
  //  const itemPrice = parseFloat(item.price && item.price.replace(',', ''));
  //  return acc + itemPrice;
  //}, 0);

  const courseIdArray = cart.map((item)=>(item.id))

  const { myStudents } = useSelector((state) => state.group);


  const { schoolEvents } = useSelector((state) => state.events);
  
  const cartToSubmit = {courses:cart,affiliateId:user &&user.affiliate} //cart.map((item)=>({...item,affiliateId:user &&user.affiliate}))
  const handleOrangeCheckBox = () => {
    setOrangeChecked(true);
    setMtnChecked1(false);
    setMtnChecked2(false);
    setPcChecked(false);
  };


  const { themeColor } = useSelector((state) => state.settings);
  const {school } = useSelector((state) => state.auth);


console.log("school Events is-->",schoolEvents)

const dateFormatter = (dateString)=>{
 

// Split the date string by "-"
let parts = dateString.split("-");

// Extract year, month, and day from parts
let year = parts[0].substring(2); // Extract last two digits of the year
let month = parts[1];
let day = parts[2];

// Create the formatted date string "DD/MM/YY"
let formattedDate = `${day}/${month}/${year}`;

return formattedDate
}


  const  [holidayStart,setHolidayStart] = useState(school && dateFormatter(school.holidayStart))
  const  [resumptionDate,setResumptionDate] = useState(school && dateFormatter(school.resumptionDate))
  const  [tuitionDeadline,setTuitionDeadline] = useState(school && dateFormatter( school.tuitionDeadline))

   //const orangeTransactionUrl = 'http://localhost:5008/api/om/transaction';
   const orangeTransactionUrl = 'https://boncole-server-2.vercel.app/api/om/transaction';


   //const orangeMTokenUrl = 'http://localhost:5008/api/om/get-token';
   // const orangeMPaymentUrl = 'http://localhost:5008/api/om/webpayment-parents';
    const orangeMTokenUrl = 'https://boncole-server-2.vercel.app/api/om/get-token';
    const orangeMPaymentUrl = 'https://boncole-server-2.vercel.app/api/om/webpayment-parents';
   


  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])

  console.log("students/children fetched--->",myStudents)

  //console.log("logged in user---->",user)
  

 // useEffect(() => {
 //   dispatch(fetchPurchasedCourse(user?.uid));
 //
 // }, []);

 useEffect(() => {
  user && (!user.students || school & !school.resumptionDate ) && navigate("/login");

 }, [user]);
 

  useEffect(() => {
   user && user.students  && dispatch(fetchMyStudents(user?.students));
 
  }, [user]);



/*TO PAY FOR ORANGE*/
useEffect(()=>{


  if(user && user.mostRecentPayToken && user.mostRecentPayToken.length){
    let userId = user && user.id
  
  
    dispatch(fetchCartToProcessFromUser(userId)).then(()=>{ 
     
      //console.log("I HAVE STEPPED PAST THE FUNCTION FOR FETCHING CART and PAY TOKEN NOW---> ")
      
  
      const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*',  
      };
      
  axios.post(orangeMTokenUrl, {}, { headers })
   .then(response => {
       const access_token = response.data.access_token;
     
      axios.post(orangeTransactionUrl, {
       amount: mostRecentOrderAmount,
       order_id: mostRecentOrderId,
       payToken:mostRecentPayToken,
       orangeMToken: access_token
     }).then((res) => {
      
         //console.log("LOOK HERE FOR INITIATED --->", res.data);
         if (res.data.status && res.data.status === 'SUCCESS' ) {
           
           const cartObject = cartToProcess
           //const courseIdArray =cartObject &&  cartObject.courses.map((item)=>(item.id))
           let today = new Date().toDateString();
         
           //console.log("COURSE ID ARRAY IS----->",courseIdArray)
          
           //dispatch(buyCourseUpdateUser(courseIdArray, user.uid, today, navigate))
           dispatch(paySchoolFees(cartObject, userId, today, navigate,res.data.txnid,res.data.order_id)).then(()=>{
             dispatch(clearPayTokenFromDatabase(userId))
           })
           
            
  
  
         }else{
           //console.log("Res", res.data);
           //console.log("AT THE HOME PAGE, MOST RECENT ORANGE PAYMENT NOT SUCCESSFUL");  
           
         }
     }).catch((error) => {
      
       console.error('could not get transaction status, so this page failed:', error);
      
     })
   }).catch(error => {
      
       notifyErrorFxn('Failed to get token');
   });
     
   
  
  })  
  
  
  }
  
  },[])
  
  /*TO PAY FOR ORANGE -- END*/
 

const parentsStudents = [
  {teacher:" Mr Vincent",level:"Level 7 ",image:" ",name:"Alexandre Francis ",studentId:" 2wqe4"},
  {teacher:" Mr Vincent",level:"Level 7 ",image:" ",name:"Charles Francis ",studentId:" 2wqe5"}
]



  return (
    <Container
      maxWidth="xs"
      sx={{ backgroundColor: 'white', border: '1px solid lightgray', fontSize: '0.85rem', minHeight: '500px' }}
    >
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between',alignItems:"center", flexDirection: 'row', marginBottom: '3rem', position: 'relative',
        top:"0.5rem", }}
      >
        <div>
          <p
            style={{
              position: 'relative',
              top:"0.5rem",
              fontWeight: 'bold',
              fontSize: '1.2rem',
              cursor:"pointer",
              width: 'max-content',
             // marginTop: '20px',
            }}
          >
           Bienvenue {user && (( user.firstName &&  user.firstName) + " " +  (user.lastName && user.lastName))}
          </p>
        </div>

        <div style={{ position: 'relative',
              top:"0.5rem",display:"flex",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
        <img  src={avatar} style={{ borderRadius:"50%" ,heigth:"45px",width:"45px",}}/> 

      
       <MdLogout style={{fontSize:"1.6rem",cursor:"pointer"}} onClick={()=>{dispatch(logout(navigate))}} />
       </div>
      </Grid>

      <Grid container xs={12} style={{ paddingTop: '1.5rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
         

         {myStudents &&
         myStudents.map((item,index)=>(
          <StudentDropdownComponent student={item} name={item.fname + " " +  item.lname} level={item.class} studentId={item.studentId} teacher={item.teacher}  index={index} profileImage={item.profileImage && item.profileImage/*" "*/} />
         ))
        }
      
    


          <br />
          <br />
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '20px',display:"flex",justifyContent:"flex-start",alignItems:"center",gap:"0.5rem" }}>
          Date Importante <FaCalendarAlt/>
          </Typography>
          <br />




       


         {schoolEvents && schoolEvents.map((item)=>(

          <Paper onClick={()=>{item.eventName == "Date Limite de Scolarité" && navigate('/dashboard/parents-payment-options')}}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
              marginBottom:"0.5rem",
              backgroundColor:item.eventName == "Date Limite de Scolarité"?(themeColor?themeColor:"#D72A34"):"#F5F5F5",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem"
              ,cursor:"pointer"
            }}
          >
            <Grid container justifyContent="space-between"  alignItems="center">
              <Grid item>
              <FaCalendarAlt style={{color: "black",fontSize:"1.4rem"}}/>
              </Grid>
              <Grid item style={{textAlign:"left",width:"50%"}} >
               {item.eventName + ":"}
              </Grid>

              <Grid item >
                {item.eventStart && dateFormatter(item.eventStart)}
              </Grid>
            </Grid>
          </Paper>

       ))
       }

       
         {/*
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
             // border: '1px solid black',
             marginBottom:"0.5rem",
              backgroundColor:"#F5F5F5",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem"
              ,cursor:"pointer"
            }}
          >
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item>
              <FaCalendarAlt style={{fontSize:"1.4rem"}}/>
              </Grid>
              <Grid item >
               Resumption Date:
              </Grid>

              <Grid item >
                {resumptionDate}
              </Grid>
            </Grid>
          </Paper> 
        */}




      {/*    <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
              //border: '1px solid black',
              backgroundColor:"#F5F5F5",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem"
              ,cursor:"pointer"
            }}
          >
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item>
                <BiParty style={{fontSize:"1.4rem"}}/>
              </Grid>
              <Grid >
               Holiday Schedule:
              </Grid>

              <Grid >
               {holidayStart}
              </Grid>

            </Grid>
          </Paper>
       */}

     <br/>
     <br/>
     <br/>


          
 

        
        </div>
      </Grid>

    
 
    </Container>
  );
}


export default WelcomeParentPage;