import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Checkbox, Typography, IconButton, Button } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



import { buyCourseUpdateUser, fetchPurchasedCourse, paySchoolFees, saveCartToDatabase, savePayTokenToDatabase } from '../redux/actions/cart.action';
import { useSelector, useDispatch } from 'react-redux';
import { buyCourse,saveOrderIdToDatabase } from 'src/redux/actions/cart.action';
import MTNLOGO from '../assets/images/MTN-logo.png';
import PAYCARDLOGO from '../assets/images/paycard-logo.png';
import ORANGELOGO from '../assets/images/orange-logo.png';
import LockIcon from '@mui/icons-material/Lock';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import axios from 'axios';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';
import { addToCart } from 'src/redux/reducers/cart.slice';



const ParentsPaymentOptions = () => {
  const [pcChecked, setPcChecked] = useState(false);
  const [mtnChecked1, setMtnChecked1] = useState(false);
  const [mtnChecked2, setMtnChecked2] = useState(false);

  const [orangeChecked, setOrangeChecked] = useState(false);
  const [momoToken, setMomoToken] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { purchasedCourses } = useSelector((state) => state.cart);
  const { myStudents } = useSelector((state) => state.group);
  //const { cart } = useSelector((state) => state.cart);

  

  //console.log("THIS IS THE STRUCTURE OF CART ITEMS--->",cart)
  const [isLoading, setIsLoading] = useState(false);

  const [applyInstallments, setApplyInstallments] = useState(false);

  const {school } = useSelector((state) => state.auth);
  console.log("school for payments--->", school)

  const [tuitionFee,setTuitionFee] = useState(

    //get the level for each student,
    //then search through the  levels array the school
    // add the tuitions for the appropriate levels up,
    // and thats the total fee


    school.tuitionFee &&  !isNaN(Number(school.tuitionFee)) ?Number(school.tuitionFee):0 
  
  
  
  
  )

  useEffect(()=>{
 
     let totalTuition =0

     let myStudentLevels = []
     const levelsForSchool = school.levels
     
     myStudents.forEach((item)=>{

      myStudentLevels.push(Number(item.class.slice(item.class.length-2,item.class.length)))
  })

  myStudentLevels.forEach((item,index)=>{
   
    for(let  i = 0;i<=school.levels.length-1;i++){ 

     if( !isNaN(Number(school.levels[i].levelName.slice(school.levels[i].levelName.length-2,school.levels[i].levelName.length))) && 
      Number(school.levels[i].levelName.slice(school.levels[i].levelName.length-2,school.levels[i].levelName.length))  === item  ){
   
     totalTuition = totalTuition + Number(school.levels[i].tuitionFeeAtLevel)

     }
    }



    setTuitionFee(totalTuition)
  })

  },[])

  const [apeae,setApeae] = useState(school.apeae && !isNaN(Number(school.apeae)) ? Number(school.apeae):0)

  const [admissionFee,setAdmissionFee] = useState(school.admissionFee && !isNaN(Number(school.admissionFee)) ? Number(school.admissionFee):0)

 
  const cart = [{title:"Tuition",price:(tuitionFee/(school.installments && applyInstallments?school.installments:1))},

    {title:"Transaction Fee (5%)",price:(tuitionFee/(school.installments && applyInstallments?school.installments:1)) * 0.05},
   
    {title:"APEAE",price:school.apeae && school.apeae},
    {title:"Admission Fee",price:school.admissionFee && school.admissionFee},
    {title:"Transaction Fee-APEAE (5%)",price:(apeae * 0.05)},
  ]

  const totalPrice = /*cart.reduce((acc, item) => {
    const itemPrice = parseFloat(item.price && item.price.replace(',', ''));
    return acc + itemPrice;
  }, 0);*/    (apeae? apeae:0) +  (apeae? (apeae*0.05):0) +  (applyInstallments?((tuitionFee +tuitionFee*0.05)/school.installments) :(tuitionFee +(tuitionFee*0.05))) + (applyInstallments?((admissionFee +admissionFee*0.05)/school.installments) :(admissionFee +(admissionFee*0.05))) 


 

  const courseIdArray = cart.map((item)=>(item.id))
  
  const cartToSubmit = {courses:cart/*,affiliateId:user &&user.affiliate*/} //cart.map((item)=>({...item,affiliateId:user &&user.affiliate}))
 



 
  const handleOrangeCheckBox = () => {
    setOrangeChecked(true);
    setMtnChecked1(false);
    setMtnChecked2(false);
    setPcChecked(false);
  };


 // const momoTokenUrl = 'http://localhost:5001/api/get-token';
 // const momoRequestToPayUrl = 'http://localhost:5001/api/requesttopay';


 const momoTokenUrl = 'https://boncole-server-2.vercel.app/api/get-token'
 const momoRequestToPayUrl = 'https://boncole-server-2.vercel.app/api/requesttopay';
 const momoTwoActionUrl = 'https://boncole-server-2.vercel.app/api/twoaction';

  //const orangeMTokenUrl = 'http://localhost:5008/api/om/get-token';
  //const orangeMPaymentUrl = 'http://localhost:5008/api/om/webpayment-parents';
 const orangeMTokenUrl = 'https://boncole-server-2.vercel.app/api/om/get-token';
 const orangeMPaymentUrl = 'https://boncole-server-2.vercel.app/api/om/webpayment-parents';

  useEffect(() => {
   // dispatch(fetchPurchasedCourse(user?.uid));

    dispatch(addToCart(cart))
 
  }, []);


  const { themeColor } = useSelector((state) => state.settings);



  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])


  const handlePcCheckboxChange = () => {
    setPcChecked(true);
    setMtnChecked1(false);
    setMtnChecked2(false);
    setOrangeChecked(false);
  };

  const handleMtnCheckboxChange = () => {
    setMtnChecked1(true);
    setMtnChecked2(false);
    setPcChecked(false);
    setOrangeChecked(false);
  };


  const handleMtnCheckboxChange2 = () => {
    setMtnChecked1(false);
    setMtnChecked2(true);
    setPcChecked(false);
    setOrangeChecked(false);
  };



  const handleMtnPay = async () => {
    if(!user){
      notifyErrorFxn("You must be logged in to proceed!");
      return;
    }


     if(user && !user.phone){
      notifyErrorFxn("Please add your phone number in the profile section before you pay via mtn");
      return;
    }

    


     const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',  
     };
       setIsLoading(true);
       axios.post(momoTokenUrl, {}, { headers })
        .then(response => {
            const access_token = response.data.access_token;
            //console.log("ACCESS-TOKEN IS-->", access_token);
           axios.post(momoTwoActionUrl, {
            amount: totalPrice,
            currency: 'GNF',
            externalId: `${uuid.v4()}`,
            payer: {
              partyIdType: 'MSISDN',
              partyId:`${user && user.phone?(user.phone).toString():null}`, 
            },
            payerMessage: 'Payment for order',
            payeeNote: 'Payment for order',
            momoToken: access_token
          }).then((res) => {
              //console.log("Payment completed...---->", res.data);
              let today = new Date().toDateString();

            if(/*res.data && res.data.status !== "PENDING" || res.data && res.data.status !== "FAILED"||*/ res.data && res.data.status === "SUCCESSFUL"|| res.data && res.data.status === "SUCCESS"){
              //dispatch(buyCourse(cartToSubmit, user.id ?? user.uid, today, navigate, setIsLoading));
              dispatch(paySchoolFees(cartToSubmit, user.id ?? user.id, today, navigate, setIsLoading));
              
             
              //dispatch(buyCourseUpdateUser(courseIdArray, user.uid, today, navigate));
              }else{

                if(res.data && res.data.reason){notifyErrorFxn(`MTN MOMO RESPONSE - ${res.data.reason}`)}
                //console.log("OUR REASON  FOR FAILURE IS HEREEE---->",res.data.reason)
              }
          }).catch((error) => {
            setIsLoading(false);
            console.error('Payment Request Error:', error);
            notifyErrorFxn('Payment Request Error...');
          })
        }).catch(error => {
            // Handle errors
            setIsLoading(false);
            console.error(' Overall Error is------->', error);
            notifyErrorFxn('Failed to get token');
        });
  };



  const navToMtnPay = () => {
    navigate('/dashboard/payment-options-mtn',{state:{cartToSubmit:cartToSubmit}})
  }

  const handleOrangePay = async () => {
    if(!user){
      notifyErrorFxn("You must be logged in to proceed!");
      return;
    }
     const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',  
     };
       setIsLoading(true);
       axios.post(orangeMTokenUrl, {}, { headers })
        .then(response => {
            const access_token = response.data.access_token;
            const generateOrderId = uuid.v4()
          
           axios.post(orangeMPaymentUrl, {
            amount: totalPrice,
            currency: 'GNF', //OUV
            order_id: `${generateOrderId}`,
            userId:user.id,
            cartData:cartToSubmit,
            reference: "ref Merchant",
            orangeMToken: access_token
          }).then((res) => {
              //console.log("RESPONSE IS--->", res.data);
              if (res.data.payment_url) {
                dispatch(savePayTokenToDatabase(user.id,res.data.pay_token,totalPrice,generateOrderId)).then(()=>{
               
                window.open(res.data.payment_url, '_blank');
                  }
                )
              }else{
                //console.log("Res", res);
                notifyErrorFxn("An error occured!");  
              }
          }).catch((error) => {
            setIsLoading(false);
            console.error('Payment Request Error:', error);
            notifyErrorFxn('Payment Request Error...');
          })
        }).catch(error => {
            setIsLoading(false);
            notifyErrorFxn('Failed to get token');
        });
  };

  return (
    <Container
      maxWidth="xs"
      sx={{ backgroundColor: 'white', border: '1px solid lightgray', fontSize: '0.85rem', minHeight: '500px' }}
    >
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '1rem',position:"relative" }}
      >
           <div  onClick ={()=>{navigate(-1)}} style={{fontSize:"2rem",color:"white",fontWeight:"900",color:themeColor?themeColor:"#C32914",position:"absolute",top:"1rem",left:"0rem",cursor:"pointer"}}>
            <FaArrowCircleLeft/>
           </div>

        <center>
          <p
            style={{
              position: 'relative',
              display: 'block',
              fontWeight: 'bold',
              fontSize: '1.3rem',
              borderBottom: `3px solid ${themeColor?themeColor:"#D72A34"}`,
              width: '250px',
              marginTop: '20px',
            }}
          >
            Payment Options
          </p>
        </center>
      </Grid>
      <Grid container xs={12} style={{ paddingTop: '1.5rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Mode de Paiement
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: 400, fontSize: '18px', marginTop: '15px', marginBottom: '15px' }}
          >
            Connexion sécurisée
            <IconButton style={{ marginLeft: '10px' }}>
              <LockIcon />
            </IconButton>
          </Typography>
    
           {/*
          <Paper
            sx={{
              p: 2,
              mb:3,
              display: 'flex',
              flexDirection: 'column',
              height: 140,
              width: 390,
              border: '0px solid black',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F4C109DB',
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Checkbox checked={mtnChecked1} onChange={handleMtnCheckboxChange} />
              </Grid>
              <Grid item style={{ marginLeft: '25%' }}>
                <img src={MTNLOGO} alt="MTN Logo" style={{ width: '100px', height: '100px' }} />
                one click method
              </Grid>
            </Grid>
          </Paper>
          */}  


          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 140,
              width: 390,
              border: '0px solid black',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#F4C109DB',
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Checkbox checked={mtnChecked2} onChange={handleMtnCheckboxChange2} />
              </Grid>
              <Grid item style={{ marginLeft: '25%' }}>
                <img src={MTNLOGO} alt="MTN Logo" style={{ width: '100px', height: '100px' }} />
                {/*two click method*/}
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 140,
              width: 390,
              border: '1px solid black',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Checkbox checked={pcChecked} onChange={handlePcCheckboxChange} />
              </Grid>
              <Grid item style={{ marginLeft: '25%' }}>
                <img src={PAYCARDLOGO} alt="PayCard Logo" style={{}} />
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 140,
              width: 390,
              border: '1px solid black',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#D85E01D1'
            }}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Checkbox checked={orangeChecked} onChange={handleOrangeCheckBox} />
              </Grid>
              <Grid item style={{ marginLeft: '15%' }}>
                <img src={ORANGELOGO} alt="Orange Logo" style={{}} />
              </Grid>
            </Grid>
          </Paper>
          <br />
          <br />
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Commande
          </Typography>
          <br />

         
            {school.tuitionFee && 
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {"Tuition"}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {school.installments && applyInstallments? (tuitionFee/school.installments):tuitionFee} GNF
                </Typography>
              </div>
            </div>}




            {school.admissionFee && 
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {"Admission Fee"}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {school.installments && applyInstallments? (admissionFee/school.installments):admissionFee} GNF
                </Typography>
              </div>
            </div>}




       {school.apeae && 
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {"APEAE"}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {apeae} GNF
                </Typography>
              </div>
            </div>
         }




{school.installments &&
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' ,display:"flex",justifyContent:"center",alignItems:"center",gap:"0rem",position:"relative"}}>
                   
                <FormControl>
    
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="active"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel onClick={(e)=>{setApplyInstallments(!applyInstallments);dispatch(addToCart(cart));dispatch(saveCartToDatabase(user.id,cartToSubmit)) }} value="active" control={<Checkbox />}  />
                  
                 </RadioGroup>
               </FormControl>
 
                   
                   <div style={{position:"relative",left:"-1rem"}}>{"Installments"}</div> 
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem'}}>
                 
               {school.installments}
               
    
                </Typography>
              </div>
            </div>}




      {school.tuitionFee && 
            
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {"Transaction Fee (5%)"}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {((apeae +   (tuitionFee/(school.installments && applyInstallments?school.installments:1)) +  (admissionFee/(school.installments && applyInstallments?school.installments:1))) * 0.05)} GNF
                </Typography>
              </div>
            </div>}







        
        </div>



      
        <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '0px solid #eee',
                marginBottom: '10px',
                marginTop:"2rem",
                width:"100%"
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {"Total"}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                  {(apeae? apeae:0)  + (apeae?apeae*0.05:0)+ (applyInstallments?((tuitionFee +tuitionFee*0.05)/school.installments) :(tuitionFee +(tuitionFee*0.05))) + (applyInstallments?((admissionFee +admissionFee*0.05)/school.installments) :(admissionFee +(admissionFee*0.05))) } GNF
                </Typography>
              </div>
            </div>
      </Grid>

      {
        <form id="paycard" action="https://mapaycard.com/epay/" method="POST">
          <input type="hidden" name="c" value="MjcyMDQxNzM" />
          <input type="hidden" name="paycard-amount" value={totalPrice} />
          <input type="hidden" name="paycard-description" value="Course sale" />
          <input
            type="hidden"
            name="paycard-callback-url"
           
            value="https://bonecole-parents.netlify.app//dashboard/payment-callback"
          />

          <input type="hidden" name="paycard-redirect-with-get" value="on" />
          <input type="hidden" name="paycard-auto-redirect" value="off" />
          <input type="hidden" name="cart_data" value={JSON.stringify(cartToSubmit)} />

         
        </form>
        }

      <center
        style={{
          marginTop: '3rem',
          marginBottom: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {pcChecked === true ? (
          <>
           {isLoading ? 
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  

         
             style={{fontSize:"1.3rem",color:"white"}}>
              Loading ...
             </Grid>

            
           </Grid>
         </Paper>
           
           
           
           
           
           : 
           
           
           <Button
           form="paycard"
           type="submit"
           disabled={isLoading === true ? true : pcChecked === false && mtnChecked1 === false ? true : false}
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  
             style={{fontSize:"1.3rem",color:"white"}}>
              Submit
             </Grid>

            
           </Grid>
         </Button>


           }
          </>
        ) : orangeChecked ? (
          <>
           {isLoading ? 
           
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  

         
             style={{fontSize:"1.3rem",color:"white"}}>
              Loading ...
             </Grid>

            
           </Grid>
         </Paper>
           
           
           
           :
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  

           onClick={() => {
             handleOrangePay();
           }}
             style={{fontSize:"1.3rem",color:"white"}}>
              Submit
             </Grid>

            
           </Grid>
         </Paper>
           
           
           
           
           }
          </>
        ) :mtnChecked2?
        
        (
          <>
           {isLoading ? 
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  

             
             style={{fontSize:"1.3rem",color:"white"}}  >
              Loading...
             </Grid>

            
           </Grid>
         </Paper>
            
           
           
           
           
           : 
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  

               onClick={() => {
                navToMtnPay();          
                 //handleMtnPay()
                }}

             style={{fontSize:"1.3rem",color:"white"}} >
              Submit
             </Grid>

            
           </Grid>
         </Paper>
           
           
           
           
           }
          </>
        )
        
        
        :
        
        (
          <>
           {isLoading ? 
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  
             style={{fontSize:"1.3rem",color:"white"}}  
             >
               Loading...
             </Grid>

            
           </Grid>
         </Paper>
           
           
           
           
           
           :
           
           
           <Paper
           sx={{
             p: 2,
             display: 'flex',
             flexDirection: 'column',
             height: 50,
             width: 390,
             marginBottom:"2rem",
             marginTop:"1rem",
             backgroundColor:themeColor?themeColor:"#D72A34",
             justifyContent: 'center',
             alignItems: 'center',
             fontSize:"1rem",
             cursor:"pointer"
           }}
         >
           <Grid container justifyContent="center" alignItems="center" >
            
             <Grid item  
             style={{fontSize:"1.3rem",color:"white"}}  
              onClick={() => {
              /*handleOrangePay();*/
            }} >
              Submit
             </Grid>

            
           </Grid>
         </Paper>
           
           }
          </>
        )}
      </center>


    {
    /*
    <br/>
      <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              width: 390,
              marginBottom:"2rem",
              marginTop:"1rem",
              backgroundColor:themeColor?themeColor:"#D72A34",
              justifyContent: 'center',
              alignItems: 'center',
              fontSize:"1rem",
              cursor:"pointer"
            }}
          >
            <Grid container justifyContent="center" alignItems="center" >
             
              <Grid item  
              style={{fontSize:"1.3rem",color:"white"}} onClick={()=>{navigate('/dashboard/home')}}>
               Submit
              </Grid>

             
            </Grid>
          </Paper>
        */
        }

    </Container>
  );
};
export default ParentsPaymentOptions;