export const PacksData = [
  {
    title: 'Pack TSS',
    description: 'toutes les matieres',
    amount: '100,000',
    length: '6',
    courses: [
      '07HxkEvmVw0S6ZxI0roX',
      '1QaF96bFSrNwcPN6Lv7A',
      '0AZhkY38NGwPmM7eKteK',
      '0PM9Yb3STEA6ngPHLc45',
      '0mBbSn2Lev6C7e3zJg29',
      '0mzZwDl5vlPdNhAGWcJt',
    ],
  },
  {
    title: 'PACK TSM',
    description: 'toutes les matieres',
    amount: '100,000',
    length: '8',
    courses: [
      '07HxkEvmVw0S6ZxI0roX',
      '0AZhkY38NGwPmM7eKteK',
      '1LwHplGvAMvquj5FEeMZ',
      '0PM9Yb3STEA6ngPHLc45',
      '1G19ot3WxORIuLvm9qJx',
      '0ecJnIiZ8zIGhTeDmlJR',
      '0mzZwDl5vlPdNhAGWcJt',
      '0tOznN94DPYP9SaKz9WR',
    ],
  },
  {
    title: 'PACK TSE',
    description: 'toutes les matieres',
    amount: '100,000',
    length: '8',
    courses: [
      '070q87K4DVdnD2W9dzNA',
      '1IMwlq1kQb6lBve5ULjj',
      '1LwHplGvAMvquj5FEeMZ',
      '0AZhkY38NGwPmM7eKteK',
      '0PM9Yb3STEA6ngPHLc45',
      '1G19ot3WxORIuLvm9qJx',
      '0ecJnIiZ8zIGhTeDmlJR',
      '0tOznN94DPYP9SaKz9WR',
    ],
  },
  {
    title: 'PACK 10eme',
    description: 'toutes les matieres',
    amount: '100,000',
    length: '6',
    courses: [
      '0AZhkY38NGwPmM7eKteK',
      '0cBMkgU7pS5jLomoSBuP',
      '0ecJnIiZ8zIGhTeDmlJR',
      '0hdt9Fd9FgN7h8pOyvEf',
      '0mBbSn2Lev6C7e3zJg29',
      '0tOznN94DPYP9SaKz9WR',
    ],
  },
  {
    title: 'PACK 6eme',
    description: 'toutes les matieres',
    amount: '100,000',
    length: '7',
    courses: [
      '0EK79ccsVjCwEymlJFTo',
      '0cBMkgU7pS5jLomoSBuP',
      '2k0rym2z3OYzjnHgSGzK',
      '1QHGkJRIe8hlpYfT3M5x',
      '0hdt9Fd9FgN7h8pOyvEf',
      '0mBbSn2Lev6C7e3zJg29',
      '1EfREw55ssQSD2KPCfiE',
    ],
  },
];

export const PackCourses = [
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Anglais',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '1',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Economie TSS',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '2',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Français',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '3',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Géographie TSS',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '4',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Histoire TSS',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '5',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Mathématiques',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '6',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Philosophie TSS',
    subjectImageUrl: ' ',
    instructor: 'Amara Sylla',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '7',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Chimie TSE/TSM',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '8',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Economie TSM',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '9',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Philosophie TSE/TSM',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '10',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Physique TSE/TSM',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '11',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Biologie TSE',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '12',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Géologie TSE',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '13',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Chimie 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '14',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'ECM 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '15',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Français 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '16',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Géographie 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '17',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Histoire 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '18',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Mathématiques 10e',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '23',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Physique 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '19',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Biologie 10e Année',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '20',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Calcul écrit 6ème',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '21',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'ECM 6ème',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '22',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Français 6ème',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '23',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Géographie 6ème',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '24',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: 'Histoire 6ème',
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '25',
  },
  {
    price: '10,000',
    masterCategory: 'Terminales',
    subCategory: 'Pack TSS',
    title: "Sciences d'observation 6ème",
    subjectImageUrl: ' ',
    subLevel: 'Terminales',
    body: 'Toutes les matières',
    uid: '4rSwyRr6yIliHKrusY5N',
    categoryId: '26',
  },
];
