import { clearAnnouncementsSuccess, clearChatsSuccess, fetchAnnouncementsSuccess, fetchChatsPending, fetchChatsSuccess, fetchInboxMessages, setCurrentAnnouncement, setCurrentChat } from "../reducers/chat.slice";

import { db, fb, auth, storage } from '../../config/firebase';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import "firebase/firestore";
import firebase from "firebase/app";

export const addToNotices = (message,senderId ) => async (dispatch) => {
   /* db.collection("notices").add({
        header: "sample event",
        message: message,
        time: Date.now()
    })*/
  const adminId = "aPmalurwLta7i2Ygrmkx4dYVfMJ2"

    db.collection('users').doc(adminId).update({
        generalNotices:firebase.firestore.FieldValue.arrayUnion({title:message,senderId:senderId})
        
      })
    .then((docRef) => {
        //console.log("Document updated is: ", docRef);
         
        
        notifySuccessFxn('notice has been added to feed.✔');
        
    })
    .catch((error) => {
        console.error("Error adding notice: ", error);
        notifyErrorFxn('Error adding notice, please try again.❌')
    });

};






export const fetchChats = (user1, user2_data) => async (dispatch) => {
    const user2 = user2_data.id;
    const users =  {user1, user2};
    console.log('User 1: ', user1);
    console.log('User 2: ', user2);

     dispatch(setCurrentChat(user2_data));
     dispatch(getRealtimeChat(users))
};



export const fetchDummyChats = (user1, user2_data) => async (dispatch) => {
    //  const user2 = user2_data.uid;
    //  const users =  {user1, user2};
    //  console.log('User 1: ', user1);
    //  console.log('User 2: ', user2);
  
       dispatch(setCurrentChat(user2_data));
      
       dispatch(fetchChatsSuccess([
       
          {isViewed:false,
           messageText:"Hey how are you doing? you got a minute ?",
           unread:0,
           user1:"yZIpS4WcZxZ150nesewtKL5U6w43",
           user2:"m1aFbUYJCDhIFrFia4EbHVoALIk1",
           time:"2024-04-21T12:57:40.205Z" 
          },
          {isViewed:true,
              messageText:"Yeah I'm fine, go ahead!",
              unread:0,
              user2:"yZIpS4WcZxZ150nesewtKL5U6w43",
              user1:"m1aFbUYJCDhIFrFia4EbHVoALIk1",
              time:"2024-04-21T12:58:40.205Z" 
             },
             {isViewed:false,
              messageText:"Okay, so I'm thinking of changing careers...",
              unread:0,
              user1:"yZIpS4WcZxZ150nesewtKL5U6w43",
              user2:"m1aFbUYJCDhIFrFia4EbHVoALIk1",
              time:"2024-04-21T12:59:40.205Z" 
             },
  
  
       ]));
  };


export const sendChat = (msgObj) => async (dispatch) => {
    
    console.log('Msg Obj: ', msgObj);
    // const today = new Date();
    // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    const today = new Date();
    const date = today.toISOString();

    const user1 = msgObj.user1;
    const user2 = msgObj.user2;
    const users =  {user1, user2};

    db.collection('chats')
    .add({
        ...msgObj,
        isViewed: false,
        unread: 0,
        time: date,
    })
    .then((snapshot) => {
        dispatch(getRealtimeChat(users))
        console.log('Sent Chat: ', snapshot);
}).catch((error) => {
        var errorMessage = error.message;
        console.log('Error sending chat', errorMessage);
});

};


export const getParentChat = (parent) => async (dispatch) => {

 dispatch(setCurrentChat(parent));

if(parent && parent.messages  && parent.messages.length > 0){
dispatch(fetchChatsSuccess(parent.messages))
}
else{
    dispatch(clearChatsSuccess([]))
}

}


export const getAnnouncementChat = (message,navigate) => async (dispatch) => {

    dispatch(setCurrentAnnouncement(message));
   
   if(message){
   dispatch(fetchAnnouncementsSuccess(message))
   }
   else{
       dispatch(clearAnnouncementsSuccess([]))
   }

   navigate('/dashboard/announcement-details')
   
   }



export const addChatToParent = (msgObj,parentId) => async (dispatch) => {
    
    console.log('Msg Obj: ', msgObj);
    // const today = new Date();
    // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    const today = new Date();
    const date = today.toISOString();

    const user1 = msgObj.user1;
    const user2 = msgObj.user2;
    const users =  {user1, user2};

    db.collection('parents').doc(parentId).get().then((doc)=>{

   const newMessages = [...doc.data().messages,{...msgObj,time: date}]

    console.log("New Messages-->",newMessages)

    db.collection('parents').doc(parentId)
    .update({
        
   /* messages:firebase.firestore.FieldValue.arrayUnion(
    
         {
        ...msgObj,
        isViewed: false,
        unread: 0,
        sentBy:"admin",
        time: date,
    }
  )*/

  messages:newMessages

})
 })
    .then((snapshot) => {

        db.collection('parents').doc(parentId).get().then((doc)=>{
            dispatch(getParentChat(doc.data()))
          }
        ).catch((error) => {
            var errorMessage = error.message;
            console.log('Error fetching parent chat update', errorMessage);
        })

        
        console.log('Sent Chat: ', snapshot);
}).catch((error) => {
        var errorMessage = error.message;
        console.log('Error updating parent chat', errorMessage);
});

};





export const getRealtimeChat = (users) => async (dispatch) => {
    db.collection('chats')
    //.where('user1', 'in', [users.user1, users.user2])
    .where('user1', 'in', ["x4fd6x7kzrNgGy3ih271J8FlNWz1", "Kvg6ZASrogPptgnUBuiXkxzhaY42"])
    .orderBy('time', 'asc')
    .onSnapshot((querySnapshot) => {
        const chats = [];
        querySnapshot.forEach(doc => {
            if(
                (doc.data().user1 == users.user1 && doc.data().user2 == users.user2)
                || 
                (doc.data().user1 == users.user2 && doc.data().user2 == users.user1)
            ){
                chats.push(doc.data())
            }
        });

        dispatch(fetchChatsSuccess(chats));
        console.log('Realtime Fetched Chats: ', chats);
        console.log('Realtime Chat Length: ', chats.length);
    })
};


export const fetchInbox = (uid) => async (dispatch) => {
    db.collection("inbox")
    .where('id', '==', uid)
     .get()
     .then((snapshot) => {
       const allInbox = snapshot.docs.map((doc) => ({ ...doc.data() }));
     if (allInbox.length > 0) {
       console.log("All Inbox Data:", allInbox);
       dispatch(fetchInboxMessages(allInbox));
     } else {
         dispatch(fetchInboxMessages(allInbox));
         console.log("No inbox!");
     }
   }).catch((error) => {
     console.log("Error getting document:", error);
   });
   };