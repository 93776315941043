import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schoolEvents: [],
  event: null,
  error: '',
  message: '',
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    saveSchoolEvents: (state, action) => {
        state.schoolEvents = action.payload;
        state.error = '';
        state.message = '';
      },
  
    clearUser: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

const { actions, reducer } = eventsSlice;

export const {
 saveSchoolEvents,
 clearUser,
} = actions;

export default reducer;


