import { Container,Grid, TextField, Typography, TextareaAutosize, Button, Paper,Divider,Box} from '@mui/material';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import randomGuy from 'src/assets/images/random-guy.jpg'
import randomWoman from 'src/assets/images/randomwoman.jpeg'
import randomWoman2 from 'src/assets/images/randomwoman2.jpg'



import { fetchGroups, fetchMyGroups, uploadUserSettings} from 'src/redux/actions/group.action';

import { useDispatch, useSelector } from 'react-redux';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import { TextInput } from "./TextInput.js";
import { MessageLeft, MessageRight } from "./Message";

import users from 'src/_mock/user';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { addChatToParent } from 'src/redux/actions/chat.action.js';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100vw",
      height: "100vh",
      maxWidth: "500px",
      maxHeight: "700px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      left:"-2rem",
     
      border: '1px solid lightgray',
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      
    },
    container: {
      maxWidth:"xs",
      //width: "100vw",
      border:"1px solid lightgray",
      height: "100vh",
      display: "flex",
      flexDirection:"column",
      position:"relative",
      alignItems: "center",
      justifyContent: "center"
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )",
      //border: '1px solid lightgray',
    }
  })
);





function MobileChatPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { selectedChatUser, chatMessages } = useSelector((state) => state.inbox);
  const { themeColor } = useSelector((state) => state.settings);
const {school,user } = useSelector((state) => state.auth);
const [messageText, setMessageText] = useState('');


console.log("Chat MESSAGES ARE---->",chatMessages)

function shouldShowContactAvatar(item, i) {
  return (
    item.user1 === selectedChatUser.id 
    && ((chatMessages[i + 1] && chatMessages[i + 1].user1 !== selectedChatUser.id) || !chatMessages[i + 1])

    // item.who === selectedContactId &&
    // ((chat.dialog[i + 1] && chat.dialog[i + 1].who !== selectedContactId) || !chat.dialog[i + 1])
  );
}



function isFirstMessageOfGroup(item, i) {
  return i === 0 || (chatMessages[i - 1] && chatMessages[i - 1].user1 !== item.user1);
  // return i === 0 || (chat.dialog[i - 1] && chat.dialog[i - 1].who !== item.who);
}

function isLastMessageOfGroup(item, i) {
  return (
    i === chatMessages.length - 1 || (chatMessages[i + 1] && chatMessages[i + 1].user1 !== item.user1)
    // i === chat.dialog.length - 1 || (chat.dialog[i + 1] && chat.dialog[i + 1].who !== item.who)
  );
}


function onInputChange(ev) {
  setMessageText(ev.target.value);
}

function onMessageSubmit(ev) {
  ev.preventDefault();

console.log("Message text--->",messageText)


  if (messageText === '') {
    return;
  }
 dispatch(
  //AGAINST sep 3 2024 - TRY AND REPRODUCE THIS IN THE PARENTS MODULE BUT USER 1 AND USER 2 WILL BE SWITCHED AROUND
   addChatToParent({
     messageText,
     user2: user.id,
     user1: selectedChatUser.id,
     sentBy:"Parent"
   },selectedChatUser.id)
 ).then(() => {
   setMessageText('');
 });
}


  return (
    

   <>
    
    {/*<div className={classes.container}>*/}
    <Container
      maxWidth="xs"
      sx={{ backgroundColor: 'white', /*border: '1px solid lightgray',*/ fontSize: '0.85rem', minHeight: '500px' }}
    >
  

      <Paper className={classes.paper}>

       
      {  <div  onClick ={()=>{navigate('/dashboard/home')}} style={{fontSize:"2rem",color:"white",fontWeight:"900",color:themeColor?themeColor:"#0c53b7",position:"relative",top:"1rem",left:"-14rem",marginBottom:"2rem",cursor:"pointer"}}>
            <FaArrowCircleLeft/>
           </div>}



        <Paper id="style-1" style={{padding:"0.5rem"}} className={classes.messagesBody}>
        
        {chatMessages && chatMessages.length > 0 ? 

            chatMessages.map((item, i) => (
              <>

      {item.sentBy === "Parent" ?
          <MessageLeft
            message={item.messageText}
            timestamp={formatDistanceToNow(new Date(item.time), { addSuffix: true })}
            photoURL={user.profileImage?user.profileImage:randomWoman2}
            displayName={user.fullName}
            avatarDisp={true}
          />
         :
          <MessageRight
            message={item.messageText}
            timestamp={formatDistanceToNow(new Date(item.time), { addSuffix: true })}
            photoURL={school && school.settings && school.settings.themeImage ?school.settings.themeImage:randomWoman}
            displayName={school?school.name.substring(0,14)+"...":"School"}
            avatarDisp={true}
          />
      }
        
         </>
            ))
       :

       
       <Typography style={{color:"black"}}>
         Start a conversation by typing your message below.
       </Typography>

      }

        </Paper>
        <br/>
        <TextInput />
        <br/>
      </Paper>

      </Container>
    {/*</div>*/}


        {/*
         <MessageLeft
            message="Hello, I am Alexandre's mother, I want to talk about his grade"
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman2}
            displayName="Mariam Henry"
            avatarDisp={true}
          />
          <MessageLeft
            message="He seems to do well in most subjects, but struggles with math, why is that ?"
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman2}
            displayName="Mariam Henry"
            avatarDisp={false}
          />
          <MessageRight
            message="Hi, I am Jacques Paul, Regarding Alexandre's grade..."
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman}
            displayName="Teacher"
            avatarDisp={true}
          />
          <MessageRight
            message="I believe he should sign up to our additional math class, what do you think?"
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman}
            displayName="Teacher"
            avatarDisp={false}
          />
  
       <MessageLeft
            message="It sounds like a good idea, what time are the classes?"
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman2}
            displayName="Mariam Henry"
            avatarDisp={true}
          />
          <MessageLeft
            message="Will he have to do them for the whole semester?"
            timestamp={'13/06 07:15'/}
            photoURL={randomWoman2}
            displayName="Mariam Henry"
            avatarDisp={false}
          />
        */}
  </> 
  );
}

export default MobileChatPage;