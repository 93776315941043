import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, signupFailed, storeUserData } from '../reducers/auth.slice';
import { v4 as uuidv4 } from 'uuid'
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { 
    saveSchoolEvents
    } from '../reducers/events.slice';


    export const fetchSchoolEvents = (schoolId) => async (dispatch) => {
 
        db.collection("events")
         .where("schoolId", '==', schoolId)
         .get()
         .then((snapshot) => {
           const studentsArray = snapshot.docs.map((doc) => ({ ...doc.data() }));
         if (studentsArray.length) {
          
           //console.log(`students for ${category} are:`, studentsArray);
           dispatch(saveSchoolEvents(studentsArray));
         } else {
           
             console.log(`No school events for this school; `);
         }
       }).catch((error) => {
         console.log("Error getting school events:", error);
        // dispatch(isItLoading(false));
       });
       };
