import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addChatToParent } from 'src/redux/actions/chat.action';


const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "95%",
        margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%"
    },
    button: {
        //margin: theme.spacing(1),
    },
  })
);


export const TextInput = () => {

    const { themeColor } = useSelector((state) => state.settings);
    const {school,user } = useSelector((state) => state.auth);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [messageText, setMessageText] = useState('');
    const { selectedChatUser, chatMessages } = useSelector((state) => state.inbox);
   


    function onInputChange(ev) {
        setMessageText(ev.target.value);
      }
      
      function onMessageSubmit(ev) {
        ev.preventDefault();
      
      console.log("Message text--->",messageText)
      
      
        if (messageText === '') {
          return;
        }
       dispatch(
        //AGAINST sep 3 2024 - TRY AND REPRODUCE THIS IN THE PARENTS MODULE BUT USER 1 AND USER 2 WILL BE SWITCHED AROUND
         addChatToParent({
           messageText,
           user2: user.id,
           user1: selectedChatUser.id,
           sentBy:"Parent"
         },selectedChatUser.id)
       ).then(() => {
         setMessageText('');
       });
      }


    return (
        <>
            <form className={classes.wrapForm} onSubmit={onMessageSubmit}  noValidate autoComplete="off">
            <TextField
                id="standard-text"
                label="send a new message..."
                className={classes.wrapText}
                //margin="normal"
                value={messageText}
                onChange={onInputChange}
            />
            <Button  type="submit" variant="contained" color="primary" className={classes.button}>
                <SendIcon /*style={{backgroundColor:themeColor?themeColor:"#D72A34"}}*/ />
            </Button>
            </form>
        </>
    )
}